<template>
  <section id="dashboard-test">

  </section>
</template>

<script>
import permission from '@core/directives/permission/index.js'

export default {
  components: {
  },
  directives: {
    permission,
  },
  data() {
    return {

    }
  },
  mounted(){

  },
  methods: {
  }
}
</script>
